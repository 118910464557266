import React, { useEffect } from "react";
import { Banner } from "../../components/Banner/Banner";
import Dash from "../../components/Dash/Dash";
import {
  thumbnail,
  blog2,
  blog3,
  blogsBanner,
  blog4Thumbnail,
  blog5Thumbnail,
  blog7,
  blog6,
  blog8,
  blog9,
  blog10,
  blog11,
  blog12,
  blog13,
} from "../../images";
import { Link, NavLink } from "react-router-dom";
//utils
import { reduceDescSize } from "../../helpers/utils";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

import "./Blog.scss";
import { blog10innerURL, blog11innerURL, blog12innerURL, blog13innerURL, blog9innerURL } from "../../helpers/paths";

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const blogListArr = [
    {
      id: 1,
      url: "/blogs/recycled-pet-chip",
      img: thumbnail,
      date: "June 2, 2022",
      category: "Lorem Ipsum",
      title: "Recycled Pet Chips 101: Explained",
      description:
        "Polyester is one of the world’s most popular textiles and it is used in thousands of different consumer and industrial applications. Worldwide, more than 22.67 billion tonnes of polyester clothes are produced each year.",
    },
    {
      id: 2,
      img: blog2,
      url: "blogs/development-yarn-fibers",
      date: "August 12, 2022",
      category: "Lorem Ipsum",
      title: "What Are the Latest Yarn, And Fabric Developments?",
      description:
        "The fabrics and yarn industry is an essential component of the textile and apparel industries.",
    },
    {
      id: 3,
      img: blog3,
      url: "blogs/technical-textiles",
      date: "August 12, 2022",
      category: "Lorem Ipsum",
      title: "What Are Some Examples Of Technical Textiles?",
      description:
        "Technical textiles in India are a vital component of modern life. These materials are employed in a wide range of industries, from medical gadgets to athletic products. This article will provide an overview of technical textiles, their examples and how it is used nowadays.",
    },
    {
      id: 4,
      img: blog4Thumbnail,
      url: "blogs/re-weaving-indias-success-story",
      date: "September 22, 2022",
      category: "Lorem Ipsum",
      title: "Re-Weaving India’s Success Story",
      description:
        "India is globally known to set examples and break benchmarks as we proceed and lead from the front.",
    },
    {
      id: 5,
      img: blog5Thumbnail,
      url: "/blogs/polyester-yarn-market-in-india",
      date: "September 22, 2022",
      category: "Lorem Ipsum",
      title: "Polyester Yarn Market Report And The Price Trend In India 2022",
      description:
        "Polyester is the most cost-effective and versatile synthetic fibre available in the industry. Given that the global growth of natural fibres is constrained by available resources, only polyester would see further capacity expansion.",
    },
    {
      id: 6,
      url: "/blogs/technical-textile-innovations",
      img: blog7,
      date: "Sep 13, 2024",
      category: "Lorem Ipsum",
      title: "5 Breakthrough innovations in technical Textiles",
      description:
        "At Sanathan Textiles, our vision drives us to continually innovate in technical textiles, reflecting our dedication to advancing industry standards and meeting diverse application needs.",
    },
    {
      id: 7,
      url: "/blogs/types-of-yarn",
      img: blog6,
      date: "Sep 13, 2024",
      category: "Lorem Ipsum",
      title: "Types of Yarn at Sanathan Textiles: Everything you need to know",
      description:
        "Sanathan Textiles is a beacon in the textile business, where innovation meets tradition, providing a wide selection of yarns to meet customer specific demands. Sanathan Textiles has something for everyone, whether you work in fashion, technical textiles, or want to find sustainable solutions. Here's a closer look at the types of  yarns we offer refined to your unique requirements.",
    },
    {
      id: 8,
      url: "/blogs/craft-your-festive-wardrobe-with-sanathan-textile-yarns-for-blazers-suits-kurtas-and-sarees",
      img: blog8,
      date: "Sep 13, 2024",
      category: "Lorem Ipsum",
      title:
        "Craft Your Festive Wardrobe with Sanathan Textile Yarns for Blazers, Suits, Kurtas, and Sarees",
      description:
        "As the festive season approaches, the demand for ethnic/Indian outfits skyrockets, making it the perfect time to enhance your offerings with fancy textile yarns. At Sanathan Textiles, we specialize in providing fancy yarns that empower customers to create elegant blazers, suits, timeless kurtas, beautiful sarees & sherwani ensuring your collections are ready for the festive seasons.",
    },
    {
      id: 9,
      url: blog9innerURL,
      img: blog9,
      date: "Nov 11, 2024",
      category: "Lorem Ipsum",
      title:
        "The Future of Polyester Yarn in Textile and Clothing - Sanathan Textiles",
      description:
        "Explore groundbreaking advancements and evolving trends in polyester yarns. Learn how innovation and sustainability are shaping the future at Sanathan Textiles.",
    },
    {
      id: 10,
      url: blog10innerURL,
      img: blog10,
      date: "Nov 11, 2024",
      category: "Lorem Ipsum",
      title:
        "Cationic Dyeable Polyester Yarn:A Revolution in Fancy Innovative Fabrics | Sanathan Textiles",
      description:
        "Transform fabric innovation with cationic dyeable polyester yarn. Explore its role in creating vibrant, versatile, and sustainable fancy fabrics at Sanathan Textiles.",
    },
    {
      id: 11,
      url: blog11innerURL,
      img: blog11,
      date: "Nov 11, 2024",
      category: "Lorem Ipsum",
      title:
        "Leveraging Technology at Sanathan Textiles: Automation, Efficiency, and Data-Driven decision",
      description:
        "The global textile industry is evolving rapidly, and businesses need reliable partners who can deliver innovation, precision, and sustainability. Sanathan Textiles emerged as a frontrunner, leveraging cutting-edge technology to redefine manufacturing processes and meet the demands of modern enterprises.",
    },
    {
      id: 12,
      url: blog12innerURL,
      img: blog12,
      date: "Nov 11, 2024",
      category: "Lorem Ipsum",
      title:
        "S-flex Yarn - A Sanathan Textile Stretch Yarn Product.",
      description:
        "The textile industry is no stranger to innovation, but every once in a while, a product emerges that redefines the landscape. Enter S-Flex, a self-stretch polyester filament yarn that is setting a new standard for stretch yarns. What makes Spandex-free S-Flex stretch truly groundbreaking is its ability to deliver four-way stretch capabilities without relying on spandex. This innovation promises to revolutionize how we think about comfort, durability, and sustainability in fabrics.",
    },
    {
      id: 13,
      url: blog13innerURL,
      img: blog13,
      date: "Nov 11, 2024",
      category: "Lorem Ipsum",
      title:
        "Sanathan Textiles Integrated Manufacturing facility with 220,000+ MTPA Capacity Offers Diverse Yarn Products and High-Value Innovation.",
      description:
        "At Sanathan Textiles' Silvassa facility, we are committed to delivering high-quality yarn products through a meticulous and transparent production process. To uphold this vision, we are committed to maintaining a meticulous and transparent production process. Our integrated manufacturing setup in Silvassa boasts a capacity exceeding 220,000 MTPA, while our facility in Punjab is designed with a capacity of 3.50,000 MTPA at peak capacity, enabling the production of cotton yarn, polyester yarn, and yarns for technical textile.",
    },
  ];

  const blogList = blogListArr.length ? (
    blogListArr.reverse().map((blog, i) => (
      <div className="col-md-6 col-lg-4 blog_details_container" key={i}>
        <div className="blog_detail_wrapper">
          <Link to={blog.url} className="blog_card">
            <div className="imageanimate zoom-out">
              <img
                src={blog.img}
                alt="caterina our blog img"
                loading="lazy"
                className="blog_img"
              />
              {/* <div className="content-overlay"></div> */}
            </div>
            <div className="text_container">
              {/* <h1 className="blog_date">{blog.date} </h1>
              <div className="verticle_line"> | </div>
              <h1 className="blog_category">{blog.category}</h1> */}
              <h2 className="blog_name"> {blog.title}</h2>
              <p className="blog_synopsis">
                {reduceDescSize(blog.description, 100)}
              </p>
              <div className="blog_cta_wrapper">
                <button className="readmore_button">Read More</button>
              </div>
            </div>
          </Link>
        </div>
      </div>
    ))
  ) : (
    <h1>No Blogs Found</h1>
  );
  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Blogs | Sanathan Textiles"
        metaDesc="Explore the world of textiles with Sanathan Textile's Insights on quality fabrics, Yarns & Customised Yarns. Visit Us and Read More Now!"
      />
      <section className="newsroom">
        <div className="wrapper">
          <div className="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} Blogs
          </div>
          <div className="left_section">
            <h1 className="hollow_title">Blogs</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <p>
              Textile blogs have now become a trending and binge-worthy blog
              category. At Sanathan Textiles, we strive to provide you with
              relevant blogs from the industry along with hot fabric/textile
              news that you can feed on!
            </p>
          </div>
          <figure className="bg_img bg_img_desktop">
            <img src={blogsBanner} alt="sanathan img" />
          </figure>
        </div>
      </section>
      <figure className="bg_img bg_img_mobile wrapper">
        <img src={blogsBanner} alt="sanathan img" />
      </figure>
      <section className="blog_sec2">
        <div className="wrapper">
          {/* <div className="breadcrumb_styles">Home {">"} Blogs</div> */}
          <div className="row m-0">{blogList}</div>
          {/* <div className="horizontal_line"></div> */}
          <div className="loadmore_cta_wrapper center"></div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
